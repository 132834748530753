<template>
  <div>
    <div
      v-b-modal.equipmentSelectModal
      class="equipmentCard-trigger"
    >
      <link-equipment
        :label="`Прикреплено - ${equipmentsCount}`"
        :request-id="requestId"
        link
        :linked="equipments"
        @updateFields="$emit('updateFields', $event)"
      />
    </div>
  </div>
</template>

<script>
import { toRefs, computed } from '@vue/composition-api';
import LinkEquipment from '@/components/facilities/LinkEquipment.vue';

export default {
  components: {
    LinkEquipment,
  },
  props: {
    equipments: Array,
    requestId: Number,
  },
  setup(props) {
    const { equipments } = toRefs(props);
    const equipmentsCount = computed(() => equipments.value?.length || 0);

    const cancel = () => {};
    const update = () => {};

    return {
      equipmentsCount,
      cancel,
      update,
    };
  },
};
</script>
