<template>
  <b-card class="equipmentCard">
    <h4 class="m-0 mb-2">
      Оборудование
    </h4>

    <EquipmentSelect
      v-if="$store.getters['user/permission']('requests:update')"
      :equipments="item.equipments_ids"
      :request-id="item.id"
      @updateFields="$emit('updateFields', $event)"
    />
    <div v-else>
      {{ `Прикреплено - ${equipmentsCount}` }}
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import { computed, toRefs } from '@vue/composition-api';
import EquipmentSelect from '@/components/fields/equipmentSelect/index.vue';

export default {
  components: {
    BCard,
    EquipmentSelect,
  },
  props: {
    item: Object,
  },
  setup(props) {
    const { item } = toRefs(props);
    const equipmentsCount = computed(() => item.value?.equipments?.length || 0);

    return {
      equipmentsCount,
    };
  },
};
</script>

<style lang="sass">
.equipmentCard
  &-trigger
    color: #7367F0
    text-decoration: underline
</style>
